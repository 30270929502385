export enum PERMISSION {
   // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  READ_DASHBOARD = 'READ_DASHBOARD',

  // MAIN: HOSTPITAL
  // -----------------------------------------------------
  READ_HOSPITAL = "READ_HOSPITAL",
  ALLOW_EDIT_HOSPITAL = "ALLOW_EDIT_HOSPITAL",

  // MAIN: HOSTPITAL REQUEST
  // -----------------------------------------------------
  READ_HOS_REQ="READ_HOS_REQ",
  ALLOW_REGISTER_HOS_REQ="ALLOW_REGISTER_HOS_REQ",
  ALLOW_APPROVE_HOS_REQ = "ALLOW_APPROVE_HOS_REQ",
  ALLOW_RETURN_HOS_REQ = "ALLOW_RETURN_HOS_REQ",
  ALLOW_EDIT_HOS_REQ = "ALLOW_EDIT_HOS_REQ",
  ALLOW_REJECT_HOS_REQ="ALLOW_REJECT_HOS_REQ",

  // MAIN: HOSTPITAL CONTRACT
  // -----------------------------------------------------
  READ_HOSPITAL_CONTRACT="READ_HOSPITAL_CONTRACT",
  ALLOW_CREATE_HOSPITAL_CONTRACT = "ALLOW_CREATE_HOSPITAL_CONTRACT",
  ALLOW_APPROVE_HOSPITAL_CONTRACT = "ALLOW_APPROVE_HOSPITAL_CONTRACT",
  ALLOW_RETURN_HOSPITAL_CONTRACT = "ALLOW_RETURN_HOSPITAL_CONTRACT",
  ALLOW_REJECT_HOSPITAL_CONTRACT="ALLOW_REJECT_HOSPITAL_CONTRACT",

  // MAIN: PHARMACY
  // -----------------------------------------------------
  READ_PHARMACY = "READ_PHARMACY",
  ALLOW_EDIT_PHARMACY = "ALLOW_EDIT_PHARMACY",

  // MAIN: PHARMACY REQUEST
  // -----------------------------------------------------
  READ_PHARMACY_REQ = "READ_PHARMACY_REQ",
  ALLOW_REGISTER_PHARMACY_REQ = "ALLOW_REGISTER_PHARMACY_REQ",
  ALLOW_APPROVE_PHARMACY_REQ = "ALLOW_APPROVE_PHARMACY_REQ",
  ALLOW_RETURN_PHARMACY_REQ = "ALLOW_RETURN_PHARMACY_REQ",
  ALLOW_REJECT_PHARMACY_REQ = "ALLOW_REJECT_PHARMACY_REQ",
  ALLOW_EDIT_PHARMACY_REQ = "ALLOW_EDIT_PHARMACY_REQ",

  // MAIN: PHARMACY CONTRACT
  // -----------------------------------------------------
  READ_PHARMACY_CONTRACT = "READ_PHARMACY_CONTRACT",
  ALLOW_CREATE_PHARMACY_CONTRACT = "ALLOW_CREATE_PHARMACY_CONTRACT",
  ALLOW_APPROVE_PHARMACY_CONTRACT = "ALLOW_APPROVE_PHARMACY_CONTRACT",
  ALLOW_RETURN_PHARMACY_CONTRACT = "ALLOW_RETURN_PHARMACY_CONTRACT",
  ALLOW_REJECT_PHARMACY_CONTRACT = "ALLOW_REJECT_PHARMACY_CONTRACT",


  // MAIN: HOS USER
  // -----------------------------------------------------
  READ_HOS_USER = "READ_HOS_USER",
  ALLOW_CREATE_HOS_USER = "ALLOW_CREATE_HOS_USER",
  ALLOW_EDIT_HOS_USER = "ALLOW_EDIT_HOS_USER",

  // -----------------------------------------------------
  // MAIN: NSSF USER
  // -----------------------------------------------------
  READ_NSSF_USER = 'READ_NSSF_USER',
  ALLOW_CREATE_NSSF_USER = 'ALLOW_CREATE_NSSF_USER',
  ALLOW_EDIT_NSSF_USER = 'ALLOW_EDIT_NSSF_USER',
  ALLOW_DISABLE_NSSF_USER = 'ALLOW_DISABLE_NSSF_USER',

  // -----------------------------------------------------
  // MAIN: SETTINGS
  // -----------------------------------------------------
  READ_SETTING = 'READ_SETTING',
  ALLOW_CREATE_SETTING = 'ALLOW_CREATE_SETTING',
  ALLOW_EDIT_SETTING = 'ALLOW_EDIT_SETTING',
  ALLOW_DISABLE_SETTING = 'ALLOW_DISABLE_SETTING',
  // -----------------------------------------------------

  // Member Ship/ Beneficiary
  READ_BEN = "READ_BEN",
  READ_PATIENT_RECORD = "READ_PATIENT_RECORD",
  ALLOW_PRINT_BEN = "ALLOW_PRINT_BEN",
  ALLOW_E_PRINT_BEN = "ALLOW_E_PRINT_BEN",
  ALLOW_SCAN_BEN = "ALLOW_SCAN_BEN",

  //  HOSPITAL CON REQUEST
  READ_CON_REQ="READ_CON_REQ",
  ALLOW_CREATE_CON_REQ ="ALLOW_CREATE_CON_REQ",
  ALLOW_APPROVE_CON_REQ="ALLOW_APPROVE_CON_REQ",
  ALLOW_RETURN_CON_REQ="ALLOW_RETURN_CON_REQ",
  ALLOW_REJECT_CON_REQ="ALLOW_REJECT_CON_REQ",
  //  HOSPITAL TRAN REQUEST
  READ_CLAIM_REQ="READ_CLAIM_REQ",
  ALLOW_CREATE_CLAIM_REQ="ALLOW_CREATE_CLAIM_REQ",
  ALLOW_APPROVE_CLAIM_REQ="ALLOW_APPROVE_CLAIM_REQ",
  ALLOW_RETURN_CLAIM_REQ="ALLOW_RETURN_CLAIM_REQ",
  ALLOW_REJECT_CLAIM_REQ="ALLOW_REJECT_CLAIM_REQ",
  ALLOW_ASSIGN_CLAIM_REQ = "ALLOW_ASSIGN_CLAIM_REQ",
  ALLOW_VERIFY_CLAIM_REQ_DETAIL= "ALLOW_VERIFY_CLAIM_REQ_DETAIL",
  //  HOSPITAL CON ASSIGN
  // READ_HOS_CON_ASSIGN_REQ="READ_HOS_CON_ASSIGN_REQ",
  // ALLOW_CREATE_HOS_CON_ASSIGN_REQ="ALLOW_CREATE_HOS_CON_ASSIGN_REQ",
  // ALLOW_APPROVE_HOS_CON_ASSIGN_REQ="ALLOW_APPROVE_HOS_CON_ASSIGN_REQ",
  // ALLOW_RETURN_HOS_CON_ASSIGN_REQ= "ALLOW_RETURN_HOS_CON_ASSIGN_REQ",
  // ALLOW_REJECT_HOS_CON_ASSIGN_REQ="ALLOW_REJECT_HOS_CON_ASSIGN_REQ",

  //  HOSPITAL INTERPRETETION
  READ_PAYMENT_REQ = "READ_PAYMENT_REQ",
  ALLOW_CREATE_PAYMENT_REQ = "ALLOW_CREATE_PAYMENT_REQ",
  ALLOW_APPROVE_PAYMENT_REQ = "ALLOW_APPROVE_PAYMENT_REQ",
  ALLOW_RETURN_PAYMENT_REQ = "ALLOW_RETURN_PAYMENT_REQ",
  ALLOW_REJECT_PAYMENT_REQ = "ALLOW_REJECT_PAYMENT_REQ",

}
export enum SECTION {
    // -----------------------------------------------------
    // MAIN: DASHBOARD
    // -----------------------------------------------------
    DASHBOARD = 'menu.dashboard',
    DASHBOARD_WORK = 'menu.dashboard',

    // MAIN: VICTIM
    // -----------------------------------------------------
    VICTIM_LIST = 'menu.victim_list',

    // MAIN: HOSPITAL REQUEST
    // -----------------------------------------------------
    HOSPITAL_REQUEST = 'setting.hospital.hospital_request',
    HOSPITAL = 'setting.hospital.hospital',

    // MAIN: HOSPITAL  REQUEST RESULT
    // -----------------------------------------------------
    HOSPITAL_RESULT = 'setting.hospital.hospital_result',

    // MAIN: HOSPITAL CONTRACT
    // -----------------------------------------------------
    HOSPITAL_CONTRACT = 'menu._hospital.hos_contract',

    // MAIN: HOSPITAL User
    // -----------------------------------------------------
    HOSPITAL_USER = 'menu._hospital.hos_manager',

    // MAIN: HOSPITAL CONTRACT
    // -----------------------------------------------------
    HOSPITAL_NONCONTRACT = 'menu._hospital.hos_noncontract',

    // MAIN: PHARMACY REQUEST
    // -----------------------------------------------------
    PHARMACY_REQUEST = 'setting.hospital.hospital_request',
    PHARMACY = 'setting.pharmacy.title',

    // MAIN: HOSPIT REQUEST
    // -----------------------------------------------------
    HOSPITAL_CON ='menu._claim_request.title',
    HOSPITAL_CON_REQUEST= 'menu.consumption.title',
    HOSPITAL_CON_TRAN_REQUEST= 'menu._claim_request.request',
    HOSPITAL_CON_ASSIGN_REQUEST= 'menu._claim_request.to_doc',
    HOS_CON_INTERPRETETION = 'menu._claim_request.req_note',
    BENEFICIARY="menu.member_ship",
    // MAIN: PHARMACY  REQUEST RESULT
    // -----------------------------------------------------
    PHARMACY_RESULT = 'setting.pharmacy.result',

    // MAIN: PHARMACY CONTRACT
    // -----------------------------------------------------
    PHARMACY_CONTRACT = 'menu._pharmacy.phar_contract',

    // MAIN: NSSF USER
    // -----------------------------------------------------
    NSSF_USER = 'menu.user_admin',

    // -----------------------------------------------------
    // MAIN: SETTINGS
    // -----------------------------------------------------
    SETTING = 'menu.setting',


}
